<template>
  <CertificatesComponentV2 v-if="clubVersion === 'v2'"/>
  <div class="containerx" v-else>
    <div class="row">
      <CertificatesComponent />
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  components: {
    CertificatesComponent: () => import("@/components/CertificatesComponent.vue"),
    CertificatesComponentV2: () => import("@/components/V2/CertificatesComponent.vue"),
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  methods: {
    getTheme() {
      const d = document;
      var theme = Cookies.get("themeClub");
      if (theme === undefined || theme === null) {
        this.themeDark = false;
      } else {
        d.documentElement.getAttribute("data-theme");
        if (theme === "dark") {
          this.themeDark = true;
        } else {
          this.themeDark = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.containerx {
  width: 100%;
}
</style>
